import React from "react";
import Survey from "../Survey";

const UnityApp = ({ boothId, needSurvey, onSubmitSurvey }) => (
  <div style={{ width: "100vw", height: "100vh" }}>
    {needSurvey && (
      <div className="overlay">
        <Survey onSubmitSurvey={onSubmitSurvey} />
      </div>
    )}
    <iframe
      src={boothId ? `/app.html?booth=${boothId}` : "/app.html"}
      width="100%"
      height="100%"
    />
  </div>
);

export default UnityApp;
