import React, { useContext } from "react";
import shuffle from "lodash/shuffle";
import { AuthContext } from "../Auth";
import { BOOTH_TO_COMPANY_ID } from "../constants";
import { copyCurrentLocation, mailToWithCurrentLocation } from "../util";

const CompanyList = ({ companies }) => {
  const { currentUser } = useContext(AuthContext);

  const companyIdToBoothId = (companyId) => {
    const COMPANY_ID_TO_BOOTH = Object.fromEntries(
      Object.entries(BOOTH_TO_COMPANY_ID).map((bc) => [bc[1], bc[0]])
    );
    return COMPANY_ID_TO_BOOTH[companyId];
  };

  return (
    <div className="companiesTop">
      <h1>講演一覧</h1>
      <ul className="seminar-list__body">
        <li className="seminar-list__item">
          <a href="/seminar/01/">
            <div className="seminar-list__item-title">
              「データ、人、プロセスを つなぐ」製造業で勝ち残る テクノロジー戦略
            </div>
            <div className="seminar-list__item-body">
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/oda.png" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>オートデスク株式会社</div>
                  <div>代表取締役社長</div>
                  <div>織田 浩義 氏</div>
                </div>
              </div>
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/jhon.png" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>オートデスク株式会社</div>
                  <div>製造業 インダストリーマネージャー</div>
                  <div>ジョン・ウォンジン 氏</div>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="seminar-list__item">
          <a href="/seminar/02/">
            <div className="seminar-list__item-title">
              AIを用いた新しい公共交通
            </div>
            <div className="seminar-list__item-body">
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/matubara.png" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>東京大学大学院</div>
                  <div> 情報理工学系研究科 AIセンター</div>
                  <div>松原 仁 教授</div>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="seminar-list__item">
          <a href="/seminar/03/">
            <div className="seminar-list__item-title">
              IoTの現在地と失敗事例
            </div>
            <div className="seminar-list__item-body">
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/suzuki.png" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>CACH株式会社</div>
                  <div>代表取締役</div>
                  <div>鈴木 良昌 氏</div>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="seminar-list__item">
          <a href="/seminar/04/">
            <div className="seminar-list__item-title">
              経営者必見！今こそやるべきコストの見直し見落としがちな間接経費にメスを入れる
            </div>
            <div className="seminar-list__item-body">
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/manda.jpg" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>ディーコープ株式会社</div>
                  <div>代表取締役社長</div>
                  <div>萬田 吉寛 氏</div>
                </div>
              </div>
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/takase.jpg" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>ディーコープ株式会社</div>
                  <div>取締役エグゼクティブアドバイザー</div>
                  <div>髙瀬 正一 氏</div>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="seminar-list__item">
          <a href="/seminar/05/">
            <div className="seminar-list__item-title">
              Zoomが考えるDXを加速させるために今求められるコト
            </div>
            <div className="seminar-list__item-body">
              <div className="seminar-list__item-speaker">
                <div className="seminar-list__item-speaker-photo">
                  <img src="https://jpn-expo.com/img/shimakata.png" />
                </div>
                <div className="seminar-list__item-speaker-name">
                  <div>ZVC Japan株式会社（Zoom）</div>
                  <div>セールスマネージャー</div>
                  <div>島方 敏 氏</div>
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <h1>出展企業一覧</h1>
      <div className="companies">
        <p className="txtRemarks center">
          下記よりクリックして各社のブースをご覧ください。
          <br />
          <span>
            ※パソコンで閲覧いただくと3Dの展示会場を歩き回ることができます。
          </span>
        </p>
        {currentUser && (
          <div className="companies__links">
            <a
              href={void 0}
              onClick={copyCurrentLocation}
              className="companies__link"
            >
              ＞ URLをコピーする
            </a>
            <a
              href={mailToWithCurrentLocation(currentUser.email)}
              className="companies__link"
            >
              ＞ メールでURLを送る
            </a>
          </div>
        )}
        <ul className="companies__list">
          {shuffle(companies).map((c) => (
            <li className="companies__list-item" key={c.id}>
              <a href={`/?booth=${companyIdToBoothId(c.id)}`}>{c.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CompanyList;
