import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { AuthContext } from "../Auth";
import app from "../base";
import { fetchUser, fetchOrCreateBusinessCard } from "../util";
import { BOOTH_TO_COMPANY_ID, STORE_KEY_BOOTH } from "../constants";
import UnityApp from "./UnityApp";
import Company from "./Company";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [needSurvey, setNeedSurvey] = useState(false);
  const boothId = localStorage.getItem(STORE_KEY_BOOTH);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const userData = await fetchUser(currentUser.uid);
        const userId = userData && Object.keys(userData)[0];
        if (!userId) {
          alert("タイムアウトしました。再度ログインしてください。");
          window.location.replace("/login");
        } else {
          const survey = await app
            .database()
            .ref(`users/${userId}/survey`)
            .once("value");
          if (survey.val()) {
            setIsReady(true);
            if (boothId) {
              const cardRef = await fetchOrCreateBusinessCard(
                currentUser.uid,
                BOOTH_TO_COMPANY_ID[boothId]
              );
              const card = (await cardRef.once("value")).val();
              const n = card.countbooth || 0;
              await cardRef.update({ countbooth: n + 1 });
            }
            localStorage.removeItem(STORE_KEY_BOOTH);
          } else {
            setNeedSurvey(true);
            setIsReady(true);
          }
        }
      })();
    }
  }, [currentUser]);

  return isReady === false ? (
    <>Loading...</>
  ) : isMobile ? (
    <Company
      boothId={boothId}
      needSurvey={needSurvey}
      onSubmitSurvey={() => setNeedSurvey(false)}
    />
  ) : (
    <UnityApp
      boothId={boothId}
      needSurvey={needSurvey}
      onSubmitSurvey={() => setNeedSurvey(false)}
    />
  );
};

export default Home;
