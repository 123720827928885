import moment from "moment";
import { isMobile } from "react-device-detect";
import app from "./base";
import { BOOTH_TO_COMPANY_ID } from "./constants";

const fetchUser = async (uid) => {
  const user = await app
    .database()
    .ref("users")
    .orderByChild("id")
    .equalTo(uid)
    .once("value");
  return user.val();
};

const fetchUserById = async (uid) => {
  const user = await app
    .database()
    .ref("users")
    .orderByChild("id")
    .equalTo(uid)
    .once("value");
  return Object.values(user.val())[0];
};

const fetchOrCreateBusinessCard = async (uid, companyId) => {
  const company = await fetchCompanyById(companyId);
  const searchKey = `${company.name}${uid}`;
  const card = await app
    .database()
    .ref("businesscards")
    .orderByChild("company_id")
    .equalTo(searchKey)
    .once("value");
  if (card.exists()) {
    return app.database().ref(`businesscards/${Object.keys(card.val())[0]}`);
  } else {
    const newRef = app.database().ref("businesscards").push();
    const data = await initialBusinessCard(uid, company);
    await newRef.set(data);
    return newRef;
  }
};

const initialBusinessCard = async (uid, company) => {
  const user = await fetchUserById(uid);
  return {
    id: uid,
    company_id: `${company.name}${uid}`,
    company_target_id: `${company.name}Booth${uid}`,
    targetCompany: company.name,
    target: "Booth",
    companyName: user.companyName,
    nickname: user.nickname,
    fieldName: user.fieldName,
    jobName: user.jobName,
    address: user.address,
    email: user.email,
    phoneNum: user.phoneNum,
    createdAt: moment().toISOString(),
    isPushed: 1,
    countbooth: 0,
    countseminar: 0,
    panel1: 0,
    countpanel1: 0,
    panel2: 0,
    countpanel2: 0,
    panel3: 0,
    countpanel3: 0,
    panel4: 0,
    countpanel4: 0,
    panel5: 0,
    countpanel5: 0,
    panel6: 0,
    countpanel6: 0,
    panel7: 0,
    countpanel7: 0,
    panel8: 0,
    countpanel8: 0,
    tv1: 0,
    counttv1: 0,
    tv2: 0,
    counttv2: 0,
    pc1: 0,
    countpc1: 0,
    catalog1: 0,
    countcatalog1: 0,
    threed1: 0,
    countthreed1: 0,
    threed2: 0,
    countthreed2: 0,
    threed3: 0,
    countthreed3: 0,
    threed4: 0,
    countthreed4: 0,
    message: 0,
    countmessage: 0,
    chat: 0,
    countchat: 0,
    zoom: 0,
    countzoom: 0,
    isMobile: isMobile ? 1 : 0,
  };
};

const fetchCompanies = async () => {
  const companies = await app.database().ref("company").once("value");
  return Object.values(companies.val()).filter((c) => c.id);
};

const fetchCompanyById = async (id) => {
  const company = await app
    .database()
    .ref("company")
    .orderByChild("id")
    .equalTo(id)
    .once("value");
  return Object.values(company.val())[0];
};

const copyCurrentLocation = () => {
  const input = document.createElement("input");
  const id = `input-${new Date().getTime()}`;
  input.id = id;
  input.type = "text";
  input.value = window.location.href;
  document.body.appendChild(input);
  document.querySelector(`#${id}`).select();
  document.execCommand("copy");
  input.remove();
  alert("URLをコピーしました");
};

const mailToWithCurrentLocation = (email) => {
  const subject = encodeURIComponent(
    "設計製造・AI・IoT・DXバーチャルオンライン展示会"
  );
  const body = encodeURIComponent(
    `ログインID: ${email}\r\nURL: ${window.location.href}`
  );
  return `mailto:${email}?subject=${subject}&body=${body}`;
};

export {
  fetchUser,
  fetchOrCreateBusinessCard,
  fetchCompanies,
  fetchCompanyById,
  copyCurrentLocation,
  mailToWithCurrentLocation,
};
