import React, { useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import app from "./base.js";
import firebase from "firebase";
import { AuthContext } from "./Auth.js";
import { STORE_KEY_REDIRECT_PATH } from "./constants";

const Login = ({ history }) => {
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;

      let isValid = true;
      if (email.value === "" || !email.value.includes("@")) {
        setError1(true);
        isValid = false;
      }
      if (password.value === "" || password.value.length < 6) {
        setError2(true);
        isValid = false;
      }
      if (isValid === true) {
        try {
          var errorCode;
          await app
            .auth()
            .signInWithEmailAndPassword(email.value, password.value)
            .catch(function (error) {
              errorCode = error.code;

              switch (errorCode) {
                case "auth/invalid-email": {
                  alert("メールアドレスの形式が正しくありません。");
                  break;
                }
                case "auth/user-disabled": {
                  alert("管理者によってユーザーアカウントが無効にされました。");
                  break;
                }
                case "auth/user-not-found": {
                  alert(
                    "この識別子に対応するユーザーレコードはありません。 ユーザーが削除された可能性があります。"
                  );
                  break;
                }
                case "auth/wrong-password": {
                  alert(
                    "パスワードが無効であるか、ユーザーがパスワードを持っていません。"
                  );
                  break;
                }
                default:
                  break;
              }
              console.log("auth error", error);
            });
        } catch (error) {
          alert(error);
        }
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (currentUser) {
      const { uid } = currentUser;
      console.log("CurrentUserID:", uid);
      localStorage.setItem("userID", uid);

      //Analysis of Login Numbers
      firebase
        .database()
        .ref("analysis")
        .once("value")
        .then(function (snapshot) {
          const val = snapshot.val();
          console.log("login is not empty", val);
          const num = (!!val ? val["login"] : 0) + 1;
          firebase.database().ref("analysis").update({ login: num });
        });
      const path = sessionStorage.getItem(STORE_KEY_REDIRECT_PATH);
      if (path) {
        sessionStorage.removeItem(STORE_KEY_REDIRECT_PATH);
        window.location.href = path;
      } else {
        window.location.href = "/";
      }
    }
  }, [currentUser]);

  return (
    <div>
      <div className="form-border">
        <div className="form-border-inn">
          <strong style={{}}></strong>
          <a
            href="/signup"
            style={{
              textUnderlineOffset: "1.5px",
              alignSelf: "center",
              marginLeft: "auto",
              float: "right",
              display: "block",
              color: "#22ac38",
              fontSize: "14px",
            }}
          >
            新規ユーザー登録
          </a>
        </div>
      </div>
      <div className="form-base">
        <div className="form-body">
          <h1>ログイン</h1>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "1.8em",
              margin: "0 0 15px",
              textAlign: "center",
            }}
          >
            ユーザー登録がお済みでない方は<a href="/signup">こちら</a>
            から新規ユーザー登録をお願いいたします。
          </p>
          <form onSubmit={handleLogin}>
            <div>
              <div className="form-section">
                <label className="form-title">メールアドレス</label>
                <div className="form-content">
                  <div className="form-section">
                    <input
                      className="form-text"
                      name="email"
                      type="email"
                      placeholder="メールアドレス"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error1 ? (
                      <p className="form-error">
                        メールアドレスが正しくありません。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="form-section">
                <label className="form-title">パスワード</label>
                <div className="form-content">
                  <div className="form-section">
                    <input
                      className="form-text"
                      name="password"
                      type="password"
                      placeholder="パスワード"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error2 ? (
                      <p className="form-error">
                        パスワードを6文字以上32文字以内で入力して下さい。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginBlockStart: "2rem" }}>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  display: "block",
                  textUnderlineOffset: "1.6px",
                  textAlign: "center",
                }}
              >
                <a href="/forgetPassword" target="_blank">
                  パスワードを忘れた方はこちら
                </a>
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  display: "block",
                  textUnderlineOffset: "1.6px",
                  textAlign: "center",
                }}
              >
                <a href="/signup" target="_blank">
                  新規ユーザー登録はこちら
                </a>
              </div>
            </div>
            <div className="form-button-base">
              <button className="form-button" type="submit">
                ログイン
              </button>
            </div>
          </form>
        </div>
      </div>
      <footer
        style={{
          background: "#000000",
          marginLeft: "auto",
          marginRight: "auto",
          bottom: "0",
          position: "fixed",
          marginBottom: "auto",
          width: "100%",
          height: "40px",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "17px",
            textAlign: "center",
            padding: "10px 0",
            marginBlockEnd: "-1em",
          }}
        >
          © 2021 VOST inc. All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default withRouter(Login);
