import React, { useContext, useState } from "react";
import { AuthContext } from "./Auth";
import app from "./base";
import { fetchUser } from "./util";

const Survey = ({ onSubmitSurvey }) => {
  const { currentUser } = useContext(AuthContext);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {
      industryType,
      jobType,
      positionType,
      employees,
      decision,
      interests,
      nextTime,
    } = event.target.elements;
    const interestValues = Array.from(interests)
      .filter((el) => el.checked)
      .map((el) => el.value);
    const nextTimeValues = Array.from(nextTime)
      .filter((el) => el.checked)
      .map((el) => el.value);

    let isValid = true;
    if (!industryType.value) {
      setError1(true);
      isValid = false;
    }
    if (!jobType.value) {
      setError2(true);
      isValid = false;
    }
    if (!positionType.value) {
      setError3(true);
      isValid = false;
    }
    if (!employees.value) {
      setError4(true);
      isValid = false;
    }
    if (!decision.value) {
      setError5(true);
      isValid = false;
    }
    if (interestValues.length === 0) {
      setError6(true);
      isValid = false;
    }
    if (nextTimeValues.length === 0) {
      setError7(true);
      isValid = false;
    }
    if (isValid === true) {
      try {
        const userData = await fetchUser(currentUser.uid);
        const userId = userData && Object.keys(userData)[0];
        if (!userId) {
          alert("タイムアウトしました。再度ログインしてください。");
          window.location.replace("/login");
        } else {
          const data = {
            industryType: industryType.value,
            jobType: jobType.value,
            positionType: positionType.value,
            employees: employees.value,
            decision: decision.value,
            interests: interestValues,
            nextTime: nextTimeValues,
          };
          await app.database().ref(`users/${userId}/survey`).update(data);
          onSubmitSurvey();
        }
      } catch (error) {
        console.error(error);
        alert(error);
      }
    }
  };

  return (
    <div className="form-base">
      <div className="form-body">
        <h1>アンケート</h1>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "1.8em",
            margin: "0 0 0px",
            textAlign: "center",
          }}
        >
          事前アンケートにご協力ください。
        </p>

        <h2>お客様情報</h2>
        <form onSubmit={handleSubmit}>
          <table>
            <tbody>
              <tr>
                <th>
                  業種<em>必須</em>
                </th>
                <td className="mail-form">
                  <select name="industryType" className="form-text">
                    <option value="">選択してください</option>
                    <option value="製造業">製造業</option>
                    <option value="通信販売業">通信販売業</option>
                    <option value="金融・保険業">金融・保険業</option>
                    <option value="通信業">通信業</option>
                    <option value="流通・小売業">流通・小売業</option>
                    <option value="運輸業">運輸業</option>
                    <option value="旅行業">旅行業</option>
                    <option value="電力・ガス・水道">電力・ガス・水道</option>
                    <option value="サービス業">サービス業</option>
                    <option value="医療・福祉">医療・福祉</option>
                    <option value="建設・不動産業">建設・不動産業</option>
                    <option value="放送・出版・マスコミ">
                      放送・出版・マスコミ
                    </option>
                    <option value="官公庁">官公庁</option>
                    <option value="地方自治体">地方自治体</option>
                    <option value="学校">学校</option>
                    <option value="その他 団体">その他 団体</option>
                  </select>
                  {error1 ? (
                    <p className="form-error">業種を選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  職種<em>必須</em>
                </th>
                <td className="mail-form">
                  <select name="jobType" className="form-text">
                    <option value="">選択してください</option>
                    <option value="経営・経営企画・業務改革">
                      経営・経営企画・業務改革
                    </option>
                    <option value="営業・販売">営業・販売</option>
                    <option value="企画・マーケティング">
                      企画・マーケティング
                    </option>
                    <option value="情報システム">情報システム</option>
                    <option value="総務・人事・経理">総務・人事・経理</option>
                    <option value="設計">設計</option>
                    <option value="研究・開発">研究・開発</option>
                    <option value="生産・製造">生産・製造</option>
                    <option value="資材・購買・調達">資材・購買・調達</option>
                    <option value="生産技術">生産技術</option>
                    <option value="生産管理">生産管理</option>
                    <option value="工場管理・施設管理">
                      工場管理・施設管理
                    </option>
                    <option value="品質管理・品質保証">
                      品質管理・品質保証
                    </option>
                    <option value="保守・メンテナンス">
                      保守・メンテナンス
                    </option>
                    <option value="官公庁・研究機関・自治体・団体">
                      官公庁・研究機関・自治体・団体
                    </option>
                    <option value="教育機関・学生">教育機関・学生</option>
                  </select>
                  {error2 ? (
                    <p className="form-error">職種を選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  役職<em>必須</em>
                </th>
                <td className="mail-form">
                  <select name="positionType" className="form-text">
                    <option value="">選択してください</option>
                    <option value="代表・経営者">代表・経営者</option>
                    <option value="役員">役員</option>
                    <option value="部長・次長">部長・次長</option>
                    <option value="課長">課長</option>
                    <option value="係長・主任">係長・主任</option>
                    <option value="一般社員">一般社員</option>
                    <option value="その他">その他</option>
                  </select>
                  {error3 ? (
                    <p className="form-error">役職を選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  企業規模<em>必須</em>
                </th>
                <td className="mail-form">
                  <div className="form-group-radio">
                    <label className="form-label">
                      <input type="radio" name="employees" value="10名未満" />
                      10名未満
                    </label>
                    <label className="form-label">
                      <input type="radio" name="employees" value="10～49名" />
                      10～49名
                    </label>
                    <label className="form-label">
                      <input type="radio" name="employees" value="50～99名" />
                      50～99名
                    </label>
                    <label className="form-label">
                      <input type="radio" name="employees" value="100～499名" />
                      100～499名
                    </label>
                    <label className="form-label">
                      <input type="radio" name="employees" value="500～999名" />
                      500～999名
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        name="employees"
                        value="1,000～4,999名"
                      />
                      1,000～4,999名
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        name="employees"
                        value="5,000～9,999名"
                      />
                      5,000～9,999名
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        name="employees"
                        value="10,000名以上"
                      />
                      10,000名以上
                    </label>
                  </div>
                  {error4 ? (
                    <p className="form-error">企業規模を選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={2}>
                  設備導入・製品購入・選定に関してどのように関与されていますか？
                  <em>必須</em>
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="form-group-radio is-nowrap">
                    <label className="form-label">
                      <input
                        type="radio"
                        name="decision"
                        value="設備導入・製品購入の決定権を持っている"
                      />
                      設備導入・製品購入の決定権を持っている
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        name="decision"
                        value="設備導入・製品購入を選定・提案する"
                      />
                      設備導入・製品購入を選定・提案する
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        name="decision"
                        value="関与していない"
                      />
                      関与していない
                    </label>
                  </div>
                  {error5 ? (
                    <p className="form-error">選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={2}>
                  ご興味のある分野をお選びください（複数回答可）<em>必須</em>
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="form-group-radio is-flexible">
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="機械・機械設備"
                      />
                      機械・機械設備
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="ERP・PLM・PDM"
                      />
                      ERP・PLM・PDM
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="CAD・CAM・CAE"
                      />
                      CAD・CAM・CAE
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="AR・VR・MR"
                      />
                      AR・VR・MR
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="IoT・M2M ソリューション"
                      />
                      IoT・M2M ソリューション
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="AI(人工知能)"
                      />
                      AI(人工知能)
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="クラウド・フォグ・エッジコンピューティング"
                      />
                      クラウド・フォグ・エッジコンピューティング
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="interests"
                        value="DX推進ソリューション"
                      />
                      DX推進ソリューション
                    </label>
                    <label className="form-label">
                      <input type="checkbox" name="interests" value="RPA" />
                      RPA
                    </label>
                  </div>
                  {error6 ? (
                    <p className="form-error">選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={2}>
                  次回以降の展示会参加について（複数回答可）<em>必須</em>
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="form-group-radio is-flexible">
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="nextTime"
                        value="出展したい"
                      />
                      出展したい
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="nextTime"
                        value="出展に興味がある"
                      />
                      出展に興味がある
                    </label>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="nextTime"
                        value="出展の予定はない"
                      />
                      出展の予定はない
                    </label>
                    <label className="form-label">
                      <input type="checkbox" name="nextTime" value="来場予定" />
                      来場予定
                    </label>
                  </div>
                  {error7 ? (
                    <p className="form-error">選択してください。</p>
                  ) : (
                    <p className="form-error"></p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="form-button-base">
            <button className="form-button" type="submit">
              送信する
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Survey;
