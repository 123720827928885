import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCUILrO1004qjEshS1tS2qE9sFXMCOZiXY",
  authDomain: "virtualtour-59981.firebaseapp.com",
  databaseURL: "https://virtualtour-2021.firebaseio.com",
  projectId: "virtualtour-59981",
  storageBucket: "virtualtour-59981.appspot.com",
  messagingSenderId: "767337598042",
});

export default app;
