export const BOOTH_TO_COMPANY_ID = {
  "1Jx3bINV7ier3hl3cBrN854ZsRw1": "xGhm6Tl9VwgXDoAtDUCLaVRg2uB3",
  d7rmO27g5HfaczSr9ED64FjzH692: "YhjEixMoGpeU782XUA6xuCBkHBg2",
  TbaKSfmNDBOimYOnbtOiBjKjMjh2: "TRTWjjpQ66TMvEVEj8EkWUg41Dw2",
  xHg55GQl3zTuuoEoJzOxJCftNDg2: "IYK2k97Q85YDSiIU13FAlssOVnB2",
  Rlmg0uzpHwNOdBldjPAxiD8oAr53: "yhq8hTunSxVQXWf5RXHr5dPPbAw2",
  Cj8doPSeeVbq7J2EwAUo3VKaHNv2: "tS1jcWumKtVpu35vbboIgVuiQLJ2",
  aLU5EfLFzZUM77vZLBDPa8Qm4mq2: "wctvG46fORbIk4Kfcyx6SegmLSr2",
  JeDBqNOSt9V3nUh1RQQBkcOVTIc2: "YCxxcwfZhtdzT4OJOLZZjCHyQ5t2",
  Rqw7ealdP7RBPxBkiVtvY13YgHn1: "22Rs2tl4yOZqNJ012lX0ZUdEPts2",
  "9WhdxsgnYZe8TjmlOumnpwF0xb63": "YUV4t6i8inVtuVr0gO5oIGE7HVI2",
  k0966MirHLTWlKLjYGAQBVEv1Mf1: "DSbvlto8gjQ19oeLphouSlkSX8j2",
  MwPhH8CZhfgcZtlYV9AORe9GmPA3: "xelRmx0VVARxbkkAEsigaYYOZev1",
  Sy5k9jgKCUXpH8SPpUFoxNOVRn92: "zuCwusDP5kanULtITkItxjRobRn1",
  "7uAlmnjKViQ7qAOC53U9PkgE2Wi2": "hA5yRvaheJUhJD09Dzk67DL5GtD3",
  uLDZeqiXyiQ91eDy9HEjL63BRWF2: "F9V97EuOJNYpFAGeddFWwiRdGzx2",
  I488VQq15sP4kAW8uXagZP4rlaW2: "5RUwVMxAZSZUuej8mpOD5GIV1713",
  "5OIBGVgiDsMW9pwUHfrdLOL73L92": "RD5XXm4CVFhN1tNjkamgQxU3it52",
  "3mElGRZ0hpZGVlPtBHhrsDb89Oo1": "CTJSyjIJl6gNIJCLWkipVnCLpkF3",
  "1iRdZM7LNQVYDn4z9D8blntThmk2": "EoNgxort17QHKoU8t0IfAiPTkZJ2",
  SUKJ97cgc3eWNVVI0FP5I1i4Z332: "JMgmkUi8b9YkLQGbk9td3TLzAvA3",
  iucJ2qezoQYSARTuan85BPHxKZl2: "slr0ajlQteVjBrlGfiqWWavwptC2",
  rEYU0rScmJVGpXSqmufaYOayZnm1: "uOsVi1JDV2gMEN72eWDsBdvgVQx1",
  ULn6fd45H1N88dbw5JWBgQ0RAq63: "tlIkHxlZlgUnu4WX8JrrBAgftfC3",
  "8hz8JkeP9nhAByaln9G1hO81W2v1": "hOWX19bP0vNACGZu76xCx5ndhq53",
  N1QMhA1RjYcegxlHihKkXBg0r8I2: "ZXfR4Cwb4tcejDLQgbERzAhBTYh2",
  MuxmbHvEwqYyIZSDmCFEBlrsmuw1: "uAOaJREo3Fhkx35Z5x33QsWK5sp1",
  KYKCr71zFPg4ommYdS1BBxAFvD53: "gdd7mJMJRMV5NSaFKkfeReQhHBq2",
  RLMtHwdqWVVf7RwzCciNiAl8KxH2: "P81JDO57WuZMobvZ4VpLlpuJWph2",
  DSGaGYO5mJhIZSMxhDkEIjo4J0z2: "AZXAsCgRLjaTDcGhGTBuPkpagET2",
  lPwyjSa8IRNZUBhchvfYLnhZLAB3: "WKhddtouoFPZWeEsoR2zCfaDn8F2",
  D3fLVQhe6RcILECFjKqWhRSQ4UP2: "H8SOaUQNOphCwp0ct4k3qhL27Mw2",
  "4MCMwOm5cwdvRzSqE3FKFXcufye2": "K7foa2DSyFY8zaqlQVhWrS8IrBk2",
  TuT1D2Q6SkOTonVcRK40rxl40tb2: "mw7M5ukjYeMg9VMcaLn7PbxQMit2",
  NlmWFYimDDbLefCuQmGnMbM2BZl1: "GjkPutSMZqSx1FC0LFPOEqsira42",
  BdeIsFT9gehl26kqS2eqGYJvE3A3: "uqVcQJFschYFF2XzvbNzbVKkYlf1",
  GvpV9HdwjUR8OWKOcAaFOJ06I2y2: "XSXrs8wSNGdMUmrNlSBBpspcTW23",
  "4UPUg1NJVpWygTsS9Gchb9XbZWo2": "raPtpkw3gfY3yD5eUsVxfGmNRym1",
  n2b2ehN1DYV6Ppy8FOGmA4O6az03: "hM9kmlzbWVNXWfstvdkVncfDiFy1",
  xOjxi9vbzcMh5OhFnoqT3Gg6K403: "fKS23Us3vTY62i0rVZo0qkTFMcH3",
  vaOHuoFynTXptlm4lHxIKnRF4kT2: "cJUIwhSkdtbpj55hlstYrdRvvgC3",
  AGSIsLYuxaZUySmDGjxKbd77MiG3: "tRQzuA0uomc2FKSXZ03IH1mErmn1",
  "3Y4XKm0rXKUkR9Jp4zJy7Isp7DX2": "qScf9U8sdDNHcOgLvWSt6IxinP72",
  rR23zC8BHjOVI0jGOhywS43CMMO2: "2xVK7MyQh3OMkWPzKasJ3xcWpu22",
  Eqow46fTbpXyaWFON2pA3oQud0u2: "YZphwMKkN9XPKsoZdnfarRxK0ht1",
  lnwtVyauYoMVb0Kcxnk4DWQsCH22: "iWCQ6H4HQVTbWCPR65OIAO1ITWl2",
  O1kXH4iVoNXciwr7cu53ZVXXDlU2: "CyC6jQVjulc6bWMsUlTC7MsreP93",
  "7TH0qHg7uURXHOWJf81QnEQJA883": "04OzstlCbnXXxMTRhNs6ePtqRS02",
  IbJL37nCP9Yy0Kw6PjFCXf8oGKY2: "M6WqLpv098S7CeUs7Z4CGdT8TOm1",
};

export const STORE_KEY_BOOTH = "store-key-booth-id";
export const STORE_KEY_REDIRECT_PATH = "store-key-redirect-path";

export const SEMINARS = [
  "IYK2k97Q85YDSiIU13FAlssOVnB2",
  "yVagFYFGVYaY9TmoU9POJPT6sCz2",
  "EoNgxort17QHKoU8t0IfAiPTkZJ2",
  "iWCQ6H4HQVTbWCPR65OIAO1ITWl2",
  "YCxxcwfZhtdzT4OJOLZZjCHyQ5t2",
];

export const SEMINAR_CONTENTS = [
  {
    id: 1,
    title: "「データ、人、プロセスを つなぐ」製造業で勝ち残る テクノロジー戦略",
    speakers: [
      {
        id: 1,
        photo: "https://jpn-expo.com/img/oda.png",
        company: "オートデスク株式会社",
        role: "代表取締役社長",
        name: "織田 浩義 氏",
      },
      {
        id: 2,
        photo: "https://jpn-expo.com/img/jhon.png",
        company: "オートデスク株式会社",
        role: "製造業 インダストリーマネージャー",
        name: "ジョン・ウォンジン 氏",
      },
    ],
    video: { id: "570879599", url: "https://player.vimeo.com/video/570879599" },
  },
  {
    id: 2,
    title: "AIを用いた新しい公共交通",
    speakers: [
      {
        id: 3,
        photo: "https://jpn-expo.com/img/matubara.png",
        company: "東京大学大学院",
        role: " 情報理工学系研究科 AIセンター",
        name: "松原 仁 教授",
      },
    ],
    video: { id: "568460771", url: "https://player.vimeo.com/video/568460771" },
  },
  {
    id: 3,
    title: "IoTの現在地と失敗事例",
    speakers: [
      {
        id: 4,
        photo: "https://jpn-expo.com/img/suzuki.png",
        company: "CACH株式会社",
        role: "代表取締役",
        name: "鈴木 良昌 氏",
      },
    ],
    video: { id: "570853769", url: "https://player.vimeo.com/video/570853769" },
  },
  {
    id: 4,
    title:
      "経営者必見！今こそやるべきコストの見直し見落としがちな間接経費にメスを入れる",
    speakers: [
      {
        id: 5,
        photo: "https://jpn-expo.com/img/manda.jpg",
        company: "ディーコープ株式会社",
        role: "代表取締役社長",
        name: "萬田 吉寛 氏",
      },
      {
        id: 7,
        photo: "https://jpn-expo.com/img/takase.jpg",
        company: "ディーコープ株式会社",
        role: "取締役エグゼクティブアドバイザー",
        name: "髙瀬 正一 氏",
      },
    ],
    video: { id: "570948474", url: "https://player.vimeo.com/video/570948474" },
  },
  {
    id: 5,
    title: "Zoomが考えるDXを加速させるために今求められるコト",
    speakers: [
      {
        id: 6,
        photo: "https://jpn-expo.com/img/shimakata.png",
        company: "ZVC Japan株式会社（Zoom）",
        role: "セールスマネージャー",
        name: "島方 敏 氏",
      },
    ],
    video: { id: "570948261", url: "https://player.vimeo.com/video/570948261" },
  },
];

export const SEMINAR_ONLY_COMPANIES = ["yVagFYFGVYaY9TmoU9POJPT6sCz2"];
