import React, { useEffect, useState } from "react";
import { BOOTH_TO_COMPANY_ID, SEMINAR_ONLY_COMPANIES } from "../constants";
import { fetchCompanies, fetchCompanyById } from "../util";
import CompanyDescription from "./CompanyDescription";
import CompanyList from "./CompanyList";
import Survey from "../Survey";

const Company = ({ boothId, needSurvey, onSubmitSurvey }) => {
  const [isLoading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    (async () => {
      if (boothId) {
        setCompany(await fetchCompanyById(BOOTH_TO_COMPANY_ID[boothId]));
        setLoading(false);
      } else {
        const companies = await fetchCompanies();
        setCompanies(
          companies.filter((c) => SEMINAR_ONLY_COMPANIES.indexOf(c.id) < 0)
        );
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div>
      {needSurvey && (
        <div className="overlay">
          <Survey onSubmitSurvey={onSubmitSurvey} />
        </div>
      )}
      {boothId ? (
        <CompanyDescription boothId={boothId} company={company} />
      ) : (
        <CompanyList companies={companies} />
      )}
    </div>
  );
};

export default Company;
