import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth";
import { copyCurrentLocation, mailToWithCurrentLocation } from "../util";

const CompanyDescription = ({ boothId, company }) => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      if (boothId) {
        const res = await fetch(
          `https://www.jpn-exhibitionhall.com/uploads/${boothId}/${boothId}.json?_=${new Date().getTime()}`
        );
        setData(await res.json());
      }
    })();
  }, [boothId]);

  if (!company || !data) {
    return <></>;
  }

  return (
    <div className={`company company-${company.id}`}>
      <h1>{company.name}</h1>
      {data["lo_u"] && <img src={data["lo_u"]} className="company__logo" />}
      {currentUser && (
        <div className="company__links">
          <a
            href={void 0}
            onClick={copyCurrentLocation}
            className="company__link"
          >
            ＞ URLをコピーする
          </a>
          <a
            href={mailToWithCurrentLocation(currentUser.email)}
            className="company__link"
          >
            ＞ メールでURLを送る
          </a>
        </div>
      )}
      {data["bs_te"] && (
        <>
          <h2>ブース案内</h2>
          <div className="boothText">
            {data["bs_te"].split("\n").map((t, i) => (
              <div key={`text-${i}-${t}`}>{t}</div>
            ))}
          </div>
        </>
      )}
      <h2>パネル</h2>
      <ul className="company__images">
        {[1, 2, 3, 4, 5, 6].map(
          (i) =>
            data[`pa_ac_${i}`] === true && (
              <li key={`panel-${i}`}>
                <a
                  href={data[`pa_fu_${i}`]}
                  target="_blank"
                  className="company__image"
                >
                  <img src={data[`pa_u_${i}`]} />
                </a>
              </li>
            )
        )}
      </ul>
      {data["tv_fu_u"] && (
        <>
          <h2>動画</h2>
          <video
            src={data["tv_fu_u"]}
            poster={data["tv_u"]}
            controls={true}
            className="company__video"
          />
          {data["pc_ac"] === true &&
            [1, 2, 3].map(
              (i) =>
                data[`pc_ta_ac_${i}`] &&
                data[`pc_u_${i}`] && (
                  <video
                    key={`pc-video-${i}`}
                    src={data[`pc_u_${i}`]}
                    controls={true}
                    className="company__video"
                  />
                )
            )}
        </>
      )}
      <h2>カタログ</h2>
      <ul className="company__images">
        {[1, 2, 3].map(
          (i) =>
            data[`ca_st_ac_${i}`] === true && (
              <li key={`catalog-${i}`}>
                <a
                  href={data[`ca_dl_${i}`]}
                  target="_blank"
                  className="company__image"
                >
                  <img src={data[`ca_u_${i}`]} />
                </a>
              </li>
            )
        )}
      </ul>
      <div className="BtnBox">
        <a href="/">出展企業一覧へ戻る</a>
      </div>
    </div>
  );
};

export default CompanyDescription;
