import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import firebase from "firebase";

const ForgetPassword = ({ history }) => {
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  let isVerified = false;

  var link = window.location.href;
  console.log("DirectLink:", link);
  var url = new URL(link);
  var mode = url.searchParams.get("mode");
  var oobCode = url.searchParams.get("oobCode");
  var apiKey = url.searchParams.get("apiKey");
  console.log("Mode:", mode, "oobCode:", oobCode, "apiKey:", apiKey);
  if (mode === "resetPassword" && mode != null) {
    isVerified = true;
  }

  const forgetPassword = useCallback(
    async (event) => {
      event.preventDefault();
      const { email } = event.target.elements;

      if (
        email.value === "" ||
        email.value.includes("@gmail.com") ||
        email.value.includes("@yahoo") ||
        email.value.includes("@outlook.com") ||
        email.value.includes("@hotmail.com")
      ) {
        setError1(true);
      } else {
        var errorCode;
        await app
          .auth()
          .sendPasswordResetEmail(email.value)
          .then(function () {
            alert("認証URLを" + email.value + "にお送りしました。");
          })
          .catch(function (error) {
            errorCode = error.code;
            switch (errorCode) {
              case "auth/invalid-email": {
                alert("メールアドレスの形式が正しくありません。");
                break;
              }
              case "auth/user-disabled": {
                alert("管理者によってユーザーアカウントが無効にされました。");
                break;
              }
              case "auth/user-not-found": {
                alert(
                  "この識別子に対応するユーザーレコードはありません。 ユーザーが削除された可能性があります。"
                );
                break;
              }
              case "auth/wrong-password": {
                alert(
                  "パスワードが無効であるか、ユーザーがパスワードを持っていません。"
                );
                break;
              }
              default:
                break;
            }
            console.log("auth error", error);
          });
      }
    },
    [history]
  );

  const updatePassword = useCallback(
    async (event) => {
      event.preventDefault();
      const { password } = event.target.elements;

      if (password.value === "" || password.value.length < 6) {
        setError2(true);
      } else {
        await app
          .auth()
          .confirmPasswordReset(oobCode, password.value)
          .then(function () {
            alert("パスワードが変更されました。");
          })
          .catch(function (error) {
            console.log("パスワードの再設定エラー", error);
          });
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="form-border">
        <div className="form-border-inn">
          <strong
            style={{
              marginBlockStart: "0.5rem",
              marginBlockEnd: "0.35rem",
              fontSize: "18px",
              color: "#2f2929",
              fontWeight: "1000",
            }}
          >
            設計製造・AI・IoT・DX バーチャルオンライン展示会
          </strong>
          <a
            href="/signup"
            style={{
              textUnderlineOffset: "1.5px",
              alignSelf: "center",
              marginLeft: "auto",
              float: "right",
              display: "block",
              color: "#22ac38",
              fontSize: "14px",
            }}
          >
            新規会員登録はこちら
          </a>
        </div>
      </div>
      <h1 style={{ marginBlockStart: "2rem", marginBlockEnd: "2rem" }}>
        パスワードを忘れてしまいましたか？
      </h1>
      <div className="form-base">
        {!isVerified ? (
          <div className="form-body">
            <td
              style={{ margin: "0.1rem", fontSize: "15px", lineHeight: "24px" }}
            >
              以下にメールアドレスを入力してください。アカウントを回復するための情報をお送りします。
            </td>
            <form onSubmit={forgetPassword} style={{ marginTop: "20px" }}>
              <div>
                <div className="form-section">
                  <label className="form-title">メールアドレス</label>
                  <div className="form-content">
                    <div className="form-section">
                      <input
                        className="form-text"
                        name="email"
                        type="email"
                        placeholder="メールアドレス"
                        required={true}
                        aria-required={true}
                        aria-invalid={true}
                      />
                      {error1 ? (
                        <p className="form-error">
                          メールアドレスが正しくありません。
                        </p>
                      ) : (
                        <p className="form-error"></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-button-base">
                <button className="form-button" type="submit">
                  パスワード再設定
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="form-body">
            <form onSubmit={updatePassword}>
              <div>
                <div className="form-section">
                  <label className="form-title">パスワード</label>
                  <div className="form-content">
                    <div className="form-section">
                      <input
                        className="form-text"
                        name="password"
                        type="password"
                        placeholder="パスワード"
                        required={true}
                        aria-required={true}
                        aria-invalid={true}
                      />
                      {error2 ? (
                        <p className="form-error">
                          パスワードを6文字以上32文字以内で入力して下さい。
                        </p>
                      ) : (
                        <p className="form-error"></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-button-base">
                <button className="form-button" type="submit">
                  再設定
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <footer
        style={{
          background: "#000000",
          marginLeft: "auto",
          marginRight: "auto",
          bottom: "0",
          position: "fixed",
          marginBottom: "auto",
          width: "100%",
          height: "60px",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "17px",
            textAlign: "center",
            marginBlockEnd: "-1em",
          }}
        >
          © 2020 VOST inc. All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default withRouter(ForgetPassword);
