import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { STORE_KEY_BOOTH, STORE_KEY_REDIRECT_PATH } from "./constants";

const params = new URLSearchParams(window.location.search);
if (params.has("booth")) {
  localStorage.setItem(STORE_KEY_BOOTH, params.get("booth"));
}
if (!sessionStorage.getItem(STORE_KEY_REDIRECT_PATH)) {
  sessionStorage.setItem(STORE_KEY_REDIRECT_PATH, window.location.pathname);
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
